import React from "react"
import CrossLogo from "../images/easter_twitter.jpg"
import Step1 from "../images/step1.jpg"
import Step2 from "../images/step2.jpg"
import Step3 from "../images/step3.jpg"
import "typeface-montserrat"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"
import SEO from "../components/seo"
import PodcastHead from "../components/PodcastHead"
import PageHeaders from "../components/pageHeaders"
import { Button } from "@chakra-ui/core"

const Wrap = styled.div`
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
`

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
`

const LinksList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const Links = styled.li`
  margin: 14px 0;
`

const Link = styled.a`
  padding: 14px;
  display: block;
  font-size: 1.2rem;
  text-transform: uppercase;
  background-color: #f07525;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  transition: ease all 0.3s;
  color: #edf2f7;
  :hover {
    opacity: 0.9;
  }
`

const IndexPage = () => (
    <>
        <Global
            styles={css`
        * {
          box-sizing: border-box;
          margin: 0;
        }

        a {
          color: #edf2f7;
          font-weight: 700;
        }

        html,
        body {
          margin: 0;
          color: #312e2e;
          font-family: -apple-system, BlinkMacSystemFont, "Montserrat",
            "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
          font-size: 14px;
          line-height: 1.4;
          background-color: #ffffff;
          padding: 15px;

          / *remove margin for the main div that Gatsby mounts into */ > div {
            margin-top: 0;
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: #312e2e;
              line-height: 1.1;

              + * {
                margin-top: 0.5rem;
              }
            }

            strong {
              color: #312e2e;
            }

            li {
              margin-top: 0.25rem;
            }
          }
        }
      `}
        />
        <SEO title="Cross Church Prayer Walk" />
        <Wrap>
            <div
                css={css`
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
            >
                <img
                    src={CrossLogo}
                    css={css`
            width: 100%;
            aspect-ratio: 16/9;
            object-fit: cover;
            object-position: 50% 50%;
            border-radius: 4px;
          `}
                />
            </div>
            <div
                css={css`
          margin-top: 4rem;
        `}
            >
                <LinksList>
                    <div>
                        <PageHeaders
                            css={css`
                display: flex;
              `}
                        >
                            Holy Week Interactive Prayer Path
                        </PageHeaders>
                        {/* Paragraph Text but have it be shown in a card-like corner radius box */}
                        <ParagraphText
                            css={css`
                margin-top: 2rem;
              `}
                        >
                            Thank you for joining us on our interactive prayer path. We
                            encourage you to take your time and walk through each station by
                            yourself, with your family, or with a group of friends. We pray
                            that you will be encouraged and challenged as you walk through
                            this prayer path.
                            <br />
                            <br />
                            Watch the video at each station and then pray through the prompt
                            given and the activity provided at each station. Let us remember
                            the sacrifice that Jesus made for us and the hope that we have in
                            Him.
                        </ParagraphText>
                    </div>
                    <div>
                        <PageHeaders
                            css={css`
                display: flex;
                margin-top: 4rem;
              `}
                            id="station1"
                        >
                            Recognition – Jesus is King
                        </PageHeaders>
                        <HeaderText></HeaderText>
                        {/* video player for youtube link, rounded corners */}
                        <iframe
                            width="100%"
                            height="315"
                            src="https://www.youtube.com/embed/AgpgdHAcEt8"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            css={css`
                border-radius: 4px;
              `}
                        ></iframe>
                        <p
                            css={css`
                font-size: 0.85rem;
                ${"" /* Style this like a scripture verse card/quote */}
                ${"" /* background-color: light gray; */}
              background-color: #edf2f7;
                color: #333333;
                padding: 1rem;
                border-radius: 4px;
                margin: 1rem 0;
              `}
                        >
                            When they approached Jerusalem and came to Bethphage at the Mount
                            of Olives, Jesus then sent two disciples, telling them, “Go into
                            the village ahead of you. At once you will find a donkey tied
                            there with her colt. Untie them and bring them to me. If anyone
                            says anything to you, say that the Lord needs them, and he will
                            send them at once.” This took place so that what was spoken
                            through the prophet might be fulfilled: Tell Daughter Zion, “See,
                            your King is coming to you, gentle, and mounted on a donkey, and
                            on a colt, the foal of a donkey.” The disciples went and did just
                            as Jesus directed them. They brought the donkey and the colt; then
                            they laid their clothes on them, and he sat on them. A very large
                            crowd spread their clothes on the road; others were cutting
                            branches from the trees and spreading them on the road. Then the
                            crowds who went ahead of him and those who followed shouted:
                            Hosanna to the Son of David! Blessed is he who comes in the name
                            of the Lord! Hosanna in the highest heaven! When he entered
                            Jerusalem, the whole city was in an uproar, saying, “Who is this?”
                            The crowds were saying, “This is the prophet Jesus from Nazareth
                            in Galilee.”
                            <br />
                            <br />- Matthew 21:1-11
                        </p>
                        {/* Paragraph Text but have it be shown in a card-like corner radius box */}
                        <ParagraphText
                            css={css`
                margin-top: 2rem;
              `}
                        >
                            The Triumphal Entry demonstrates Jesus as the King of Kings, take
                            time now to pray in worship to our God.
                        </ParagraphText>
                        <ParagraphText
                            css={css`
                margin-top: 2rem;
              `}
                        >
                            <b>Prayer Prompt:</b>
                            <br /> "Jesus you are worthy of our worship because..."
                        </ParagraphText>
                    </div>
                    <div>
                        <PageHeaders
                            css={css`
                display: flex;
                margin-top: 8rem;
              `}
                            id="station2"
                        >
                            Remembrance – Jesus is Savior
                        </PageHeaders>
                        <HeaderText></HeaderText>
                        {/* video player for youtube link, rounded corners */}
                        <iframe
                            width="100%"
                            height="315"
                            src="https://www.youtube.com/embed/U7Ai7CS93dM"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            css={css`
                border-radius: 4px;
              `}
                        ></iframe>
                        {/* Paragraph Text but have it be shown in a card-like corner radius box */}
                        <p
                            css={css`
                font-size: 0.85rem;
                ${"" /* Style this like a scripture verse card/quote */}
                ${"" /* background-color: light gray; */}
                background-color: #edf2f7;
                color: #333333;
                padding: 1rem;
                border-radius: 4px;
                margin: 1rem 0;
              `}
                        >
                            As they were eating, Jesus took bread, blessed and broke it, gave
                            it to the disciples, and said, “Take and eat it; this is my body.”
                            Then he took a cup, and after giving thanks, he gave it to them
                            and said, “Drink from it, all of you. For this is my blood of the
                            covenant, which is poured out for many for the forgiveness of
                            sins. But I tell you, I will not drink from this fruit of the vine
                            from now on until that day when I drink it new with you in my
                            Father’s kingdom.” After singing a hymn, they went out to the
                            Mount of Olives.
                            <br />
                            <br />- Matthew 26:26-30
                        </p>

                        <ParagraphText
                            css={css`
                margin-top: 2rem;
              `}
                        >
                            In the Upper Room Jesus gives instructions on remembering His
                            death and resurrection in bringing us salvation. Take time to
                            remember the price Jesus paid for the sins of the world.
                        </ParagraphText>
                        <ParagraphText
                            css={css`
                margin-top: 2rem;
              `}
                        >
                            <b>Prayer Prompt:</b>
                            <br /> "Jesus, thank you for enduring…"
                        </ParagraphText>
                        <ParagraphText
                            css={css`
                margin-top: 2rem;
              `}
                        >
                            <b>Activity:</b>
                            <br /> "After prayer, take communion provided at the station."
                        </ParagraphText>
                    </div>
                    <div>
                        <PageHeaders
                            css={css`
                display: flex;
                margin-top: 8rem;
              `}
                            id="station3"
                        >
                            Restoration – Jesus is Sacrificed
                        </PageHeaders>
                        <HeaderText></HeaderText>
                        {/* video player for youtube link, rounded corners */}
                        <iframe
                            width="100%"
                            height="315"
                            src="https://www.youtube.com/embed/e3lx6s0d0oU"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            css={css`
                border-radius: 4px;
              `}
                        ></iframe>
                        {/* Paragraph Text but have it be shown in a card-like corner radius box */}
                        <p
                            css={css`
                font-size: 0.85rem;
                ${"" /* Style this like a scripture verse card/quote */}
                ${"" /* background-color: light gray; */}
                background-color: #edf2f7;
                color: #333333;
                padding: 1rem;
                border-radius: 4px;
                margin: 1rem 0;
              `}
                        >
                            From noon until three in the afternoon, darkness came over the
                            whole land. About three in the afternoon Jesus cried out with a
                            loud voice, “Elí, Elí, lemá sabachtháni?” that is, “My God, my
                            God, why have you abandoned me?”
                            <br />
                            <br />
                            When some of those standing there heard this, they said, “He’s
                            calling for Elijah.”
                            <br />
                            <br />
                            Immediately one of them ran and got a sponge, filled it with sour
                            wine, put it on a stick, and offered him a drink. But the rest
                            said, “Let’s see if Elijah comes to save him.”
                            <br />
                            <br />
                            But Jesus cried out again with a loud voice and gave up his
                            spirit. Suddenly, the curtain of the sanctuary was torn in two
                            from top to bottom, the earth quaked, and the rocks were split.
                            The tombs were also opened and many bodies of the saints who had
                            fallen asleep were raised. And they came out of the tombs after
                            his resurrection, entered the holy city, and appeared to many.
                            <br />
                            <br />
                            When the centurion and those with him, who were keeping watch over
                            Jesus, saw the earthquake and the things that had happened, they
                            were terrified and said, “Truly this man was the Son of God!”
                            <br />
                            <br />- Matthew 27:45-54
                        </p>

                        <ParagraphText
                            css={css`
                margin-top: 2rem;
              `}
                        >
                            The death of Jesus on a cross is a picture of the pain and price
                            of our sin. A price we cannot afford to pay and a price only a
                            perfect and holy God can pay for us. Because of our sin, Jesus had
                            to die on the cross. Take time to confess the sins in your life.
                        </ParagraphText>
                        <ParagraphText
                            css={css`
                margin-top: 2rem;
              `}
                        >
                            <b>Prayer Prompt:</b>
                            <br /> "Jesus, forgive me for…"
                        </ParagraphText>
                        <ParagraphText
                            css={css`
                margin-top: 2rem;
              `}
                        >
                            <b>Activity:</b>
                            <br /> "After prayer, write your sin on a piece of paper and nail
                            it to the cross."
                        </ParagraphText>
                    </div>
                    <div>
                        <PageHeaders
                            css={css`
                display: flex;
                margin-top: 8rem;
              `}
                            id="station4"
                        >
                            Revival – Jesus is Alive
                        </PageHeaders>
                        <HeaderText></HeaderText>
                        {/* video player for youtube link, rounded corners */}
                        <iframe
                            width="100%"
                            height="315"
                            src="https://www.youtube.com/embed/CAwbbABQEOE"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            css={css`
                border-radius: 4px;
              `}
                        ></iframe>
                        {/* Paragraph Text but have it be shown in a card-like corner radius box */}
                        <p
                            css={css`
                font-size: 0.85rem;
                ${"" /* Style this like a scripture verse card/quote */}
                ${"" /* background-color: light gray; */}
                background-color: #edf2f7;
                color: #333333;
                padding: 1rem;
                border-radius: 4px;
                margin: 1rem 0;
              `}
                        >
                            After the Sabbath, as the first day of the week was dawning, Mary
                            Magdalene and the other Mary went to view the tomb. There was a
                            violent earthquake, because an angel of the Lord descended from
                            heaven and approached the tomb. He rolled back the stone and was
                            sitting on it. His appearance was like lightning, and his clothing
                            was as white as snow. The guards were so shaken by fear of him
                            that they became like dead men.
                            <br />
                            <br />
                            The angel told the women, “Don’t be afraid, because I know you are
                            looking for Jesus who was crucified. He is not here. For he has
                            risen, just as he said. Come and see the place where he lay. Then
                            go quickly and tell his disciples, ‘He has risen from the dead and
                            indeed he is going ahead of you to Galilee; you will see him
                            there.’ Listen, I have told you.”
                            <br />
                            <br />
                            So, departing quickly from the tomb with fear and great joy, they
                            ran to tell his disciples the news.
                            <br />
                            <br />- Matthew 28:1-8
                        </p>

                        <ParagraphText
                            css={css`
                margin-top: 2rem;
              `}
                        >
                            The resurrection of Jesus is the ultimate proof that Jesus is
                            King, Jesus is our Savior, Jesus is the Sacrificial Lamb, and
                            Jesus is God! We know all of this because Jesus is Alive!
                        </ParagraphText>
                        <ParagraphText
                            css={css`
                margin-top: 2rem;
              `}
                        >
                            <b>Prayer Prompt:</b>
                            <br /> "Jesus, because You are alive, I experience a new life of…"{" "}
                            <br />
                            <br />
                            "Jesus, because You are alive, (lost family member, friend,
                            neighbor, etc.) can experience a new life!"
                        </ParagraphText>
                        <ParagraphText
                            css={css`
                margin-top: 2rem;
              `}
                        >
                            <b>Activity:</b>
                            <br /> "After prayer, take an Easter invite card and share the
                            digital invites below to friends, family members, co-workers,
                            neighbors, and all who need to hear the Good News that Jesus is
                            Alive!"
                        </ParagraphText>
                    </div>
                    <div>
                        <PageHeaders
                            css={css`
                display: flex;
                margin-top: 8rem;
              `}
                        >
                            Easter Invite
                        </PageHeaders>
                        <HeaderText>
                            We pray this has been a time of reflection and refreshment as we
                            examine and pray through the journey of our Lord and Savior Jesus
                            Christ...
                        </HeaderText>
                        <ParagraphText
                            css={css`
                margin-top: 2rem;
              `}
                        >
                            We want to invite you to join us for our Easter service on Sunday,
                            April 9th at any of our three campuses. <br />
                            <a
                                href="/easter"
                                css={css`
                  color: black;
                `}
                            >
                                Find more info about our Easter services here.
                            </a>
                        </ParagraphText>
                        <br />
                        <ParagraphText>
                            Take an invite card, invite your friends, send a text, and invite
                            your friends to the social links below to celebrate with us this
                            Easter that Jesus is ALIVE!
                        </ParagraphText>
                        {/* Two buttons side by side. One to link to the facebook event and the other to invite a friend */}
                        <div
                            css={css`
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-top: 2rem;
              `}
                        >
                            <a
                                css={css`
                  width: 100%;
                  ${"" /* styled like a button */}
                  background-color: #333333;
                  color: white;
                  padding: 1rem;
                  border-radius: 4px;
                  text-align: center;
                  text-decoration: none;
                  margin: 1rem 0;
                  ${"" /* Hover styles */}
                  &:hover {
                    background-color: #4a4a4a;
                    color: white;
                  }
                `}
                                href="sms:?&body=I'm%20attending%20Easter%20at%20Cross%20Church%20on%20March%2031st.%0AYou%20should%20come%20with%20me!%0Ahttps://crosschurchcares.com/easter"
                            >
                                Text a Friend
                            </a>
                            <a
                                css={css`
                  width: 100%;
                  ${"" /* styled like a clear button with an outline */}
                  border: 3px solid #333333;
                  background-color: transparent;
                  color: #333333;
                  padding: 1rem;
                  border-radius: 4px;
                  text-align: center;
                  text-decoration: none;
                  margin: 1rem 0;
                  ${"" /* Hover styles */}
                  &:hover {
                    background-color: #4a4a4a;
                    color: white;
                  }
                `}
                                href=""
                            >
                                Facebook Event - Surprise
                            </a>
                            <a
                                css={css`
                  width: 100%;
                  ${"" /* styled like a clear button with an outline */}
                  border: 3px solid #333333;
                  background-color: transparent;
                  color: #333333;
                  padding: 1rem;
                  border-radius: 4px;
                  text-align: center;
                  text-decoration: none;
                  margin: 1rem 0;
                  ${"" /* Hover styles */}
                  &:hover {
                    background-color: #4a4a4a;
                    color: white;
                  }
                `}
                                href=""
                            >
                                Facebook Event - Phoenix
                            </a>
                            <a
                                css={css`
                  width: 100%;
                  ${"" /* styled like a clear button with an outline */}
                  border: 3px solid #333333;
                  background-color: transparent;
                  color: #333333;
                  padding: 1rem;
                  border-radius: 4px;
                  text-align: center;
                  text-decoration: none;
                  margin: 1rem 0;
                  ${"" /* Hover styles */}
                  &:hover {
                    background-color: #4a4a4a;
                    color: white;
                  }
                `}
                                href=""
                            >
                                Facebook Event - Cornville
                            </a>
                            <a
                                css={css`
                  width: 100%;
                  ${"" /* styled like a clear button with an outline */}
                  border: 3px solid #333333;
                  background-color: transparent;
                  color: #333333;
                  padding: 1rem;
                  border-radius: 4px;
                  text-align: center;
                  text-decoration: none;
                  margin: 1rem 0;
                  ${"" /* Hover styles */}
                  &:hover {
                    background-color: #4a4a4a;
                    color: white;
                  }
                `}
                                href=""
                            >
                                Facebook Event - El Mirage
                            </a>
                        </div>
                    </div>
                </LinksList>
            </div>
        </Wrap>
    </>
)

export default IndexPage
